var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("single-property", {
        attrs: {
          title: "Name",
          content: _vm.data.first_name + " " + _vm.data.last_name
        }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Email", content: _vm.data.email }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Phone number", content: _vm.data.phone_number }
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-4" }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Company", content: _vm.data.company }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: {
          title: "Address",
          content:
            _vm.data.address +
            ", " +
            _vm.data.post_code +
            " " +
            _vm.data.country
        }
      }),
      _vm._v(" "),
      _c("single-property", { attrs: { title: "VAT", content: _vm.data.vat } }),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-4" }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Created at", content: _vm.data.created_at }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: {
          title: "E-Mail verified at",
          content: _vm.data.email_verified_at
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }