<template>
    <div>
        <div class="remodal" data-remodal-id="modal" data-remodal-options="hashTracking: false">
            <button data-remodal-action="close" class="remodal-close"></button>
            <component v-bind:is="currentModal" v-bind:data="data"></component>
        </div>
    </div>
</template>
 
<script>

    import { EventBus } from '../event-bus';

    export default {
        data: function ( ) {
            return {
                data: {
                    route: 'empty'
                }
            }
        },
        computed: {
            currentModal: function ( ) {
                return 'details-' + this.data.route;
            }
        },
        mounted( ) {
            
            EventBus.$on( 'remodal', data => {
                this.data = data;
            });

        },
    }
</script>