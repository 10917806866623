    /**
     * First we will load all of this project's JavaScript dependencies which
     * includes Vue and other libraries. It is a great starting point when
     * building robust, powerful web applications using Vue and Laravel.
     */

    import './bootstrap';

    window.toastr = require( 'toastr' );
    window.Vue = require('vue');

    import Clipboard from 'clipboard';


    require( 'bootstrap/dist/js/bootstrap.bundle.min.js' );

    Vue.component( 'crud', require( './Components/CRUD.vue' ) );
    Vue.component( 'details-keys', require( './Details/Keys.vue' ) );
    Vue.component( 'details-empty', require( './Details/Empty.vue' ) );
    Vue.component( 'details-users', require( './Details/Users.vue' ) );
    Vue.component( 'remodal', require( './Components/Remodal.vue' ) );
    Vue.component( 'single-property', require( './Components/SingleProperty.vue' ) );
    Vue.component( 'loading-item', require( './Components/LoadingItem.vue' ) );

    var app = new Vue({
        el: '#app',
        data: {
            loading: true
        },
        created: function( ) {

            var root = this;

            setTimeout( ( ) => {
                root.loading = false;
            }, 2000 );
            
        }
    });
 
    $( document ).ready( function( ) {
    
        new Clipboard( '.copy' );

        $( '[data-toastr-status="success"]' ).each( function( i, e ) {
            
            toastr.options.positionClass = 'toast-bottom';

            toastr.success( $( e ).attr( 'data-toastr-message' ) );

        });

        $( '[data-toggle="popover"]' ).popover( );

    });