<template>
    <div>
        <single-property title="Name" :content="data.first_name + ' ' + data.last_name"></single-property>
        <single-property title="Email" :content="data.email"></single-property>
        <single-property title="Phone number" :content="data.phone_number"></single-property>
        <hr class="mb-4" />
        <single-property title="Company" :content="data.company"></single-property>
        <single-property title="Address" :content="data.address + ', ' +  data.post_code + ' ' + data.country"></single-property>
        <single-property title="VAT" :content="data.vat"></single-property>
        <hr class="mb-4" />
        <single-property title="Created at" :content="data.created_at"></single-property>
        <single-property title="E-Mail verified at" :content="data.email_verified_at"></single-property>
    </div>
</template>
 
<script>

    export default {
        props: [
            'data'
        ]
    }
    
</script>