<template>
    <div class="dropdown-menu acc-box-shadow acc-border-radius text-center">
        <a href="javascript:void( 0 );" class="dropdown-item text-dark" @click="show">Show</a>
        <a v-bind:href="this.data.route + '/' + this.data.id + '/edit'" class="dropdown-item text-dark" v-if="data.admin">Edit</a>
        <a href="javascript:void( 0 );" class="dropdown-item text-danger" @click="destroy" v-if="data.admin">Delete</a>
    </div>
</template>
 
<script>

    import $ from 'jquery';
    import Vue from 'vue';
    import axios from 'axios'
    import 'remodal';
    import { EventBus } from '../event-bus';

    export default {
        props: [
            'data'

        ],
        methods: {
            destroy( ) {
                
                axios.delete( window.location.pathname + '/' + this.data.id ).then( response => {
                    location.reload( );
                });
                
            },
            show( ) {

                console.log( this.$route );

                EventBus.$emit( 'remodal', this.data );

                var remodal = $( '[data-remodal-id=modal]' ).remodal( );

                remodal.open( );
                
            }
        }
    }
</script>