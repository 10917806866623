var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("single-property", { attrs: { title: "Key", content: _vm.data.key } }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Domain", content: _vm.data.domain }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Last used at", content: _vm.data.last_used_at }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Last IP Address", content: _vm.data.last_ip_address }
      }),
      _vm._v(" "),
      _c("single-property", {
        attrs: { title: "Created at", content: _vm.data.created_at.date }
      }),
      _vm._v(" "),
      _vm.data.deleted_at
        ? _c("single-property", {
            attrs: {
              title: "Status",
              content: "Inactive",
              layout: "badge",
              badge_class: "danger"
            }
          })
        : _c("single-property", {
            attrs: {
              title: "Status",
              content: "Active",
              layout: "badge",
              badge_class: "success"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }