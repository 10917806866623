var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.content
    ? _c("div", { staticClass: "mb-4" }, [
        _c("h6", [_vm._v(_vm._s(this.title))]),
        _vm._v(" "),
        this.layout == "badge"
          ? _c("div", [
              _c(
                "span",
                { staticClass: "badge", class: "badge-" + this.badge_class },
                [_vm._v(_vm._s(this.content))]
              )
            ])
          : _c("div", [_c("p", [_vm._v(_vm._s(this.content))])])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }