<template>
    <div class="mb-4" v-if="this.content">
        <h6>{{ this.title }}</h6>
        <div v-if="this.layout == 'badge'">
            <span class="badge" v-bind:class="'badge-' + this.badge_class">{{ this.content }}</span>
        </div>
        <div v-else>
            <p>{{ this.content }}</p>
        </div>
    </div>
</template>
 
<script>

    export default {
        props: [
            'title',
            'content',
            'layout',
            'badge_class',
        ]
    }
</script>