<template>

</template>
 
<script>

    export default {
        props: [
            'count'
        ]
    }

</script>