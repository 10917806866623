var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropdown-menu acc-box-shadow acc-border-radius text-center"
    },
    [
      _c(
        "a",
        {
          staticClass: "dropdown-item text-dark",
          attrs: { href: "javascript:void( 0 );" },
          on: { click: _vm.show }
        },
        [_vm._v("Show")]
      ),
      _vm._v(" "),
      _vm.data.admin
        ? _c(
            "a",
            {
              staticClass: "dropdown-item text-dark",
              attrs: { href: this.data.route + "/" + this.data.id + "/edit" }
            },
            [_vm._v("Edit")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.admin
        ? _c(
            "a",
            {
              staticClass: "dropdown-item text-danger",
              attrs: { href: "javascript:void( 0 );" },
              on: { click: _vm.destroy }
            },
            [_vm._v("Delete")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }