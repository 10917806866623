var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "remodal",
        attrs: {
          "data-remodal-id": "modal",
          "data-remodal-options": "hashTracking: false"
        }
      },
      [
        _c("button", {
          staticClass: "remodal-close",
          attrs: { "data-remodal-action": "close" }
        }),
        _vm._v(" "),
        _c(_vm.currentModal, { tag: "component", attrs: { data: _vm.data } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }