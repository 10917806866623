<template>
    <div>
        <single-property title="Key" :content="data.key"></single-property>
        <single-property title="Domain" :content="data.domain"></single-property>
        <single-property title="Last used at" :content="data.last_used_at"></single-property>
        <single-property title="Last IP Address" :content="data.last_ip_address"></single-property>
        <single-property title="Created at" :content="data.created_at.date"></single-property>
        <single-property v-if="data.deleted_at" title="Status" content="Inactive" layout="badge" badge_class="danger"></single-property>
        <single-property v-else="" title="Status" content="Active" layout="badge" badge_class="success"></single-property>
    </div>
</template>
 
<script>

    export default {
        props: [
            'data'
        ]
    }
    
</script>